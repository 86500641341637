import React, { useContext } from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import { useRouter } from "next/router"
import { Grid, Avatar, Box, makeStyles, Button } from "@material-ui/core"
import { UserContext } from "services/User/context"
import { isEmpty } from "lodash"
import { imageSrcHelper } from "settings"
import LeaveReviewIcon from "@material-ui/icons/Send"
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined"
// import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded"

const useStyles = makeStyles(({ spacing }) => ({
  head: {
    color: "#fff",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    background:
      "-webkit-gradient(linear,left bottom,left top,from(#0281d0),to(#419fda)), linear-gradient(0deg,#0281d0,#419fda)",
    borderBottomRightRadius: "20px",
    borderBottomLeftRadius: "20px",
    gridTemplateColumns: "100%",
  },

  homeHead: {
    gridTemplateRows: "[row1-start] 50px [row1-end] 80px auto 2fr 1fr",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    gridTemplateColumns: "100%",
  },

  topHead: {
    gridRow: "1",
    justifySelf: "end",

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    width: "100%",
  },

  topMenu: {
    alignSelf: "initial",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: spacing(5, 0),
    "& > div": {
      // backgroundColor: 'red',
      // margin: '5px',
      justifyContent: "center",
      alignSelf: "center",
      whiteSpace: "nowrap",
    },
    "& a": {
      color: "white",
      fontWeight: "500",
      cursor: "pointer",
      textDecoration: "none",
    },
  },

  motto: {
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
    gridRow: "4",
    gridGap: "20px",

    "& h2": {
      display: "block",
      color: "white",
      fontWeight: "800",
      width: "80%",
      textAlign: "center",
      fontSize: "1.5rem",
    },

    "& a": {
      width: "100%",
      textDecoration: "none",
    },
  },
  backArrow: {
    minWidth: 40,
  },
  logo: {
    minWidth: 40,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    "& img": {
      objectFit: "contain",
      width: "50%",
      maxWidth: 420,
    },
  },
  userAvatar: {
    minWidth: 40,
  },
}))

export const Header = (props) => {
  const { title = undefined, content = undefined, isHome = false, backToHome } = props

  const { user } = useContext(UserContext)
  const router = useRouter()
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.head} style={isHome ? { minHeight: "60vh" } : undefined}>
      <div className={classes.topHead}>
        <Box className={classes.backArrow}>
          {!isHome && (
            <ArrowBackOutlinedIcon
              onClick={() => {
                backToHome ? router.push("/") : router.back()
              }}
              style={{ cursor: "pointer" }}
            />
          )}
        </Box>
        <Box className={classes.logo}>
          <Link href="/">
            <img src="/images/badtraveller_logo_v3.png" alt="Badtraveller" />
          </Link>
        </Box>
        <Box className={classes.userAvatar}>
          <Link href="/area-utente">
            <Avatar style={{ cursor: "pointer" }} src={imageSrcHelper(user?.avatar, "thumbnail")}>
              {!isEmpty(user)
                ? `${user?.firstname && user?.firstname[0]}${user?.lastname && user?.lastname[0]}`.toUpperCase()
                : undefined}
            </Avatar>
          </Link>
        </Box>
      </div>
      {title && (
        <Grid item xs={12} className={classes.motto}>
          <h2 style={!isHome ? { width: "100%", textAlign: "left", marginBottom: 0 } : undefined}>{title}</h2>
        </Grid>
      )}
      {isHome && (
        <>
          <div className={classes.topMenu}>
            <Grid item>
              <Link href="/disabili-in-viaggio/chi-siamo">
                <a>Chi Siamo</a>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/disabili-in-viaggio/mission">
                <a>Mission</a>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/viaggi-accessibili/blog">
                <a>Blog</a>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/forum">
                <a>Forum</a>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/disabili-in-viaggio/contatti">
                <a>Contatti</a>
              </Link>
            </Grid>
          </div>
          <Grid container justify="center" alignItems="center"></Grid>
          <div className={classes.motto}>
            <img src="/images/badtraveller.gif" alt="Badtraveller - Il portale di riferimento per ..." width={100} />
            <h2>Il primo portale dedicato alla disabilità in viaggio</h2>
          </div>
          <div>
            <Box mb={2}>
              <Link href="/area-utente/lascia-recensione">
                <Button variant="contained" color="default" className={classes.button} startIcon={<LeaveReviewIcon />}>
                  Condividi la tua esperienza
                </Button>
              </Link>
            </Box>
            <Box>
              <Link href="/esplora">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  // startIcon={<LeaveReviewIcon />}
                >
                  Esplora le strutture
                </Button>
              </Link>
              <Link href="/esplora/mappa">
                <Box mt={2} style={{ color: "white", textAlign: "center", cursor: "pointer" }}>
                  oppure vedi sulla mappa
                </Box>
              </Link>
            </Box>
          </div>
        </>
      )}

      {content}
    </Grid>
  )
}

Header.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
  isHome: PropTypes.bool,
  backToHome: PropTypes.bool,
}
